import {HOME_HANDLE} from '../constants';
import {getCookie} from '../utils/cookie';
import type {Store, Track} from '../types';
import {MonorailEventSchema} from '../../schema-types';
import {ApprovedCookie} from '../../enums';

const DEFAULT_APPLICATION_NAME = 'default-application';

const pathSplit = (path: string, index: number) => path.split('/')[index];

export const transformPageName = (urlStr = '') => {
  const {pathname} = new URL(urlStr);
  const pathnameHomeFix = pathname === '/' ? '/home' : pathname;
  const pathnameLeadSlash = pathnameHomeFix.substring(1) || '';
  const pathSlashFix = pathnameLeadSlash.replace(/\//g, ':');
  return `shopify:${pathSlashFix}`;
};

export const parseUrlHandle = (href: string | null | undefined) => {
  if (href) {
    try {
      const {pathname} = new URL(href);
      return (
        pathname.split('/').filter(Boolean).join('/') || HOME_HANDLE
      ).toLowerCase();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('malformed href', err);
    }
  }
  return '';
};

export const getAltPageHandle = (href: string, pathPrefix?: string) => {
  // try link alternate
  let handle = parseUrlHandle(
    document
      .querySelector('link[rel="alternate"][hreflang="en"]')
      ?.getAttribute('href'),
  );

  // try link canonical
  if (!handle) {
    handle = parseUrlHandle(
      document.querySelector('link[rel="canonical"]')?.getAttribute('href'),
    );
  }

  // use current path
  if (!handle) {
    handle = parseUrlHandle(href);
  }

  // default to 'home' for all home pages
  if (pathPrefix && handle === pathPrefix.toLowerCase()) {
    handle = HOME_HANDLE;
  }

  return handle;
};

export const initPageViewTracking = (track: Track, store: Store) => {
  const url = new URL(location.toString());
  const domain = url.hostname;
  const path = url.pathname;
  const handle =
    store.metadata?.handle ||
    getAltPageHandle(url.href, store?.metadata?.site?.pathPrefix);
  const referrer = document.referrer || '';
  const experimentVariationId =
    store.metadata?.page?.experimentVariationId ||
    store.experimentVariationId ||
    '';
  const pageViewToken = store.pageViewToken || '';
  const siteEnvironment = store.mode || 'development';

  track.dux({
    schemaId: MonorailEventSchema.PageView,
    payload: {
      pageViewToken: store.pageViewToken || '',
      userLocale: (navigator && navigator.language) || '',
      url: location.toString(),
      domain,
      path,
      urlHash: (location && location.hash) || '',
      canvasHeight: Math.round(document.body.clientHeight),
      canvasWidth: Math.round(document.body.clientWidth),
      viewportHeight: Math.round(window.innerHeight),
      viewportWidth: Math.round(window.innerWidth),
      version: process.env.VERSION || '',
      siteEnvironment,
      application: store.service || DEFAULT_APPLICATION_NAME,
      handle,
      shopId: store.shopId,
    },
  });

  if (store.metadata) {
    const multiTrackToken = store.multiTrackToken || '';
    const sessionToken = store.sessionToken || '';

    const {metadata} = store;
    const {site, page, regions, extraData, title} = metadata;

    const currency = extraData?.currencyCode || site?.currencyCode || 'USD';
    if (store.emitTrekkiePageViewEvent && site && page && regions) {
      const english = regions.find(({base}) => base);
      const pageEnglishUrl = english?.href || '';

      track.dux(
        {
          schemaId: MonorailEventSchema.TrekkiePageView,
          payload: {
            application: store.service || DEFAULT_APPLICATION_NAME,
            uniqToken: multiTrackToken,
            visitToken: sessionToken,
            url: url.toString(),
            microSessionId: pageViewToken,
            locale: site.locale || '',
            pagePurpose: page.pageCategory || '',
            httpCode: page.httpCode || '',
            pageEnglishUrl,
            envName: siteEnvironment,
            userLanguage: (navigator && navigator.language) || '',
            pageName: pageEnglishUrl ? transformPageName(pageEnglishUrl) : '',
            envCurrency: currency,
            pageTopic: pathSplit(url.pathname, site.pathPrefix ? 2 : 1) || '',
            pageSubtopic:
              pathSplit(url.pathname, site.pathPrefix ? 3 : 2) || '',
            affiliate: getCookie(ApprovedCookie.Source) || '',
            pageOwner: page.pageGroup || '',
            path: url.pathname,
            search: url.search,
            referrer,
            title,
          },
        },
        {preserveCase: ['uniqToken', 'visitToken', 'microSessionId']},
      );
    }

    track.dux(
      {
        userLanguage: (navigator && navigator.language) || '',
        url: url.toString(),
        referrer,
        httpStatusCode: handle === 'error-404' ? '404' : '200',
        experimentVariationId,
        metadata: store.metadata,
      },
      {flush: true},
    );
  }
};

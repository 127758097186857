/* eslint-disable @typescript-eslint/naming-convention */
import type {ConsentAllStatus} from '../enums';
import type {ConfigMetadata, Mode, DuxEvent} from '../types';

// eslint-disable-next-line @shopify/typescript/prefer-singular-enums
export enum Events {
  BeforeUnload = 'beforeunload',
  Error = 'error',
  Load = 'load',
  PageHide = 'pagehide',
  PageShow = 'pageshow',
  Scroll = 'scroll',
  VisibilityChange = 'visibilitychange',
}

export enum GtmEventType {
  ComponentViewability = 'component_viewability',
}

export interface GtmClickEventData {
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
  eventValue?: string;
  dimension1?: string;
  /* @deprecated Removing in favor of `event` */
  eventName?: string;
  event?: string;
}

export interface GtmComponentViewabilityData {
  event: GtmEventType.ComponentViewability;
  eventType?: string;
  eventLocation?: string;
  extraMetadata?: string;
}

export interface GtmScrollEventData {
  event: Events.Scroll;
  scrollDepth: number;
}

export interface GtmContext {
  emailAddress?: string;
  shopName?: string;
}

export type GtmEvent = GtmClickEventData & {
  project: string;
  user_token: string;
  event: string;
  event_non_interaction: string;
  event_context: GtmContext;
};

export type PageViewCallback = (store: Store) => {
  [key: string]: string | number | boolean | undefined;
};
export interface DuxConfig {
  mode: Mode;
  service: string;
  metadata?: ConfigMetadata;
  enableActiveConsent?: boolean;
  enableGtm?: boolean;
  enableGtmLoader?: boolean;
  onPageView?: PageViewCallback;
  emitTrekkiePageViewEvent?: boolean;
  eventHandlerEndpoint?: string;
  countryCode?: string;
  regionCode?: string;
  canonicalUrl?: string;
  shopId?: string;
}

export interface ComputedSiteData {
  isReady: boolean;
  multiTrackToken: string;
  pageViewToken: string;
  sessionToken: string;
  complianceZone: string;
  url: string;
  pageLanguageCode?: string;
  lastShopDomain?: string;
  isNewUser?: boolean;
}

export interface Store extends ComputedSiteData {
  debug?: boolean;
  emitTrekkiePageViewEvent?: boolean;
  enableActiveConsent?: boolean;
  enableGtm?: boolean;
  eventHandlerEndpoint?: string;
  metadata?: ConfigMetadata;
  mode?: Mode;
  service: string;
  countryCode?: string;
  regionCode?: string;
  canonicalUrl?: string;
  experimentVariationId?: string;
  shopId?: string;
}

export interface DuxTrackerOptions {
  preserveCase?: string[];
  flush?: boolean;
}

export interface Track {
  dux: (event: DuxEvent, options?: DuxTrackerOptions) => void;
  gtm?: (
    event: GtmClickEventData | GtmScrollEventData | GtmComponentViewabilityData,
    context?: GtmContext,
  ) => void;
}

export type UpdateTrackers = (trackers: Track) => void;
export type UpdateStore = (store: Partial<Store>) => void;

export interface PrivacySettings {
  consentedAll: ConsentAllStatus;
  countryCode: string;
  regionCode?: string;
  complianceZone: string;
  date: string;
  rootDomain: string;
  url: string;
  apiVersion: string;
}

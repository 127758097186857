import {DOMAIN_LOCALE_MAPPINGS} from '../client/constants';

export const localeFromUrl = (url: URL): string | null => {
  const domain = url.host;

  // handle dev and staging domains and default to 'en'
  if (domain === 'www.myshopify.io' || domain.startsWith('upcoming')) {
    return 'en';
  }

  const locale = DOMAIN_LOCALE_MAPPINGS[domain];

  if (!locale) {
    return null;
  }

  if (typeof locale === 'string') {
    return locale;
  }

  const pathPrefix = url.pathname.match(/\/([^/]+)/);

  if (pathPrefix) {
    const localeFolder = pathPrefix[1];

    return locale[localeFolder] ? locale[localeFolder] : locale.default;
  }

  return null;
};

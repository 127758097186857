import type {WebsiteClickEvent_1_5} from '@shopify/monorail/lib/schemas/schema-declarations/website-click-event-1-5';
import type {WebsiteClientPageView_2_9} from '@shopify/monorail/lib/schemas/schema-declarations/website-client-page-view-2-9';
import type {WebsiteComponentViewabilityEvent_1_3} from '@shopify/monorail/lib/schemas/schema-declarations/website-component-viewability-event-1-3';
import type {WebsiteErrorEvent_1_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-error-event-1-0';
import type {WebsiteFormEvent_2_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-form-event-2-0';
import type {WebsiteMarketingId_1_0} from '@shopify/monorail/lib/schemas';
import type {WebsitePrivacySignal_1_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-privacy-signal-1-0';
import type {WebsiteScrollEvent_1_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-scroll-event-1-0';
import type {WebsiteServerPageView_2_9} from '@shopify/monorail/lib/schemas/schema-declarations/website-server-page-view-2-9';
import type {WebsiteTrafficQuality_1_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-traffic-quality-1-0';
import type {WebsiteUserSession_1_1} from '@shopify/monorail/lib/schemas/schema-declarations/website-user-session-1-1';
import type {WebsiteVisibilityChangeEvent_1_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-visibility-change-event-1-0';
import type {WebsiteWebVitalsEvent_2_0} from '@shopify/monorail/lib/schemas/schema-declarations/website-web-vitals-event-2-0';
import type {TrekkieBrochurePageView_1_3} from '@shopify/monorail/lib/schemas/schema-declarations/trekkie-brochure-page-view-1-3';
import type {ConversionSignalPageview_2_1} from '@shopify/monorail/lib/schemas/schema-declarations/conversion-signal-pageview-2-1';
import type {Metadata} from '@shopify/monorail/lib/metadata/metadata';
import type {HttpProducerOptions} from '@shopify/monorail/lib/producers/http-producer-options';

export type MonorailEvent = (
  | WebsiteClickEvent_1_5
  | WebsiteClientPageView_2_9
  | WebsiteComponentViewabilityEvent_1_3
  | WebsiteErrorEvent_1_0
  | WebsiteFormEvent_2_0
  | WebsiteMarketingId_1_0
  | WebsitePrivacySignal_1_0
  | WebsiteScrollEvent_1_0
  | WebsiteServerPageView_2_9
  | WebsiteTrafficQuality_1_0
  | WebsiteUserSession_1_1
  | WebsiteVisibilityChangeEvent_1_0
  | WebsiteWebVitalsEvent_2_0
  | TrekkieBrochurePageView_1_3
  | ConversionSignalPageview_2_1
) & {
  metadata?: Metadata;
  options?: HttpProducerOptions;
};

export enum MonorailEventSchema {
  Click = 'website_click_event/1.5',
  ComponentViewability = 'website_component_viewability_event/1.3',
  ConversionSignalPageView = 'conversion_signal_pageview/2.1',
  Error = 'website_error_event/1.0',
  Form = 'website_form_event/2.0',
  MarketingId = 'website_marketing_id/1.0',
  MarketingPageEvent = 'marketing_page_event/2.0',
  PageView = 'website_client_page_view/2.9',
  ServerPageView = 'website_server_page_view/2.9',
  Scroll = 'website_scroll_event/1.0',
  TrekkiePageView = 'trekkie_brochure_page_view/1.3',
  UserSession = 'website_user_session/1.1',
  VisibilityChange = 'website_visibility_change_event/1.0',
  WebVitals = 'website_web_vitals_event/2.0',
  PrivacySignal = 'website_privacy_signal/1.0',
  TrafficQuality = 'website_traffic_quality/1.0',
}

import {v4} from 'uuid';

import {ApprovedCookie, SameSite} from '../enums';
import {localeFromUrl} from '../utils/localeFromUrl';
import type {ConfigMetadata, DataLayer, Site} from '../types';

import {DUX_INITIALIZED_ATTRIBUTE} from './constants';
import {
  initClickTracking,
  initComponentViewabilityTracking,
  initErrorTracking,
  initFormTracking,
  initPageViewTracking,
  initScrollTracking,
  initTrafficQualityTracking,
  initVisibilityTracking,
  initWebVitalsTracking,
} from './loggers';
import {DuxTracker, GtmTracker} from './tracker';
import type {DuxConfig, Store, UpdateStore, UpdateTrackers} from './types';
import {getCookie, setCookie} from './utils/cookie';
import {removeAllListeners} from './utils/listeners';
import {getMicroSessionId} from './utils/trekkie';
import {getComplianceZone} from './utils/complianceZone';
import extractRootDomain from './utils/extractRootDomain';

export {addViewabilityObserver, removeViewabilityObserver} from './loggers';

export const duxStore: Partial<Store> = {
  debug: true,
  emitTrekkiePageViewEvent: true,
  enableActiveConsent: false,
  isReady: false,
};

export const init = async (
  options: DuxConfig,
  updateStore?: UpdateStore,
  updateTrackers?: UpdateTrackers,
  initStore: Partial<Store> = duxStore,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (document.body.hasAttribute(DUX_INITIALIZED_ATTRIBUTE)) {
    // eslint-disable-next-line no-console
    console.log(
      `[dux] warning: only initialize dux once / <StrictMode> may be enabled which re-renders in development mode.`,
      options,
    );
    return;
  }

  // clean up any listeners that may have been added for a previously loaded page
  removeAllListeners();
  document.body.setAttribute(DUX_INITIALIZED_ATTRIBUTE, '1');

  const {
    emitTrekkiePageViewEvent,
    enableGtm,
    metadata,
    mode,
    service,
    eventHandlerEndpoint,
    countryCode,
    regionCode,
    canonicalUrl,
    ...siteData
  } = options;

  // if we are not emitting a Trekkie page view event it is because Trekkie is
  // already loaded on the page, so we read in microSessionId and appName
  // from Trekkie configuration
  const pageViewToken = emitTrekkiePageViewEvent
    ? v4()
    : await getMicroSessionId();

  const href = window.location.href;
  const currentURL = new URL(href);
  const domain = extractRootDomain(currentURL);

  // initialize user and session cookies, if they are not already present
  const persistedMultiTrackToken = getCookie(ApprovedCookie.MultiTrackToken);
  const multiTrackToken = persistedMultiTrackToken || v4();
  if (multiTrackToken !== persistedMultiTrackToken) {
    setCookie(ApprovedCookie.MultiTrackToken, multiTrackToken, {
      ...(domain ? {domain} : {}),
      maxage: 365 * 24 * 60 * 60 * 1000,
      path: '/',
      secure: true,
      samesite: SameSite.Lax,
    });
  }

  const persistedSessionToken = getCookie(ApprovedCookie.SessionToken);
  const sessionToken = persistedSessionToken || v4();
  if (sessionToken !== persistedSessionToken) {
    setCookie(ApprovedCookie.SessionToken, sessionToken, {
      ...(domain ? {domain} : {}),
      maxage: 30 * 60 * 1000,
      path: '/',
      secure: true,
      samesite: SameSite.Lax,
    });
  }

  const pathPrefix =
    metadata?.site?.pathPrefix || localeFromUrl(currentURL) || '';
  const experimentVariationId = metadata?.page?.experimentVariationId || '';

  const page: DataLayer = {
    ...(metadata?.page as DataLayer),
    affiliate:
      metadata?.page?.affiliate ||
      currentURL.searchParams.get('partner') ||
      getCookie(ApprovedCookie.Affiliate) ||
      '',
    experimentVariationId,
  };

  const site: Site = {
    ...(metadata?.site as Site),
    pathPrefix,
  };

  const store: Store = {
    ...initStore,
    emitTrekkiePageViewEvent,
    enableGtm,
    eventHandlerEndpoint,
    mode,
    service,
    countryCode,
    regionCode,
    multiTrackToken,
    pageViewToken,
    sessionToken,
    url: currentURL.toString(),
    complianceZone: getComplianceZone(countryCode, regionCode),
    isNewUser: multiTrackToken !== persistedMultiTrackToken,
    pageLanguageCode: pathPrefix?.substring(0, pathPrefix.indexOf('-')) || 'en',
    lastShopDomain: getCookie(ApprovedCookie.LastShop),
    canonicalUrl:
      canonicalUrl ||
      document.querySelector('link[rel="canonical"]')?.getAttribute('href') ||
      currentURL.href.split(/[?#]/)[0],
    experimentVariationId,
    metadata: {
      ...metadata,
      title: metadata?.title || document.title,
      language: metadata?.page?.language || navigator.language || '',
      page,
      site,
    } as ConfigMetadata,
    isReady: true,
    ...siteData,
  };

  const trackers = {
    dux: new DuxTracker(store).track,
    gtm: enableGtm ? new GtmTracker(store).track : undefined,
  };

  if (updateStore) {
    updateStore(store);
  }

  if (updateTrackers && trackers) {
    updateTrackers(trackers);
  }

  // initialize all loggers
  initPageViewTracking(trackers, store);
  initClickTracking(trackers, store, [document.body]);
  initErrorTracking(trackers, store);
  initVisibilityTracking(trackers, store);
  initWebVitalsTracking(trackers, store);
  initTrafficQualityTracking(trackers, store);
  initComponentViewabilityTracking(trackers, store);
  initScrollTracking(trackers, store);
  initFormTracking(trackers, store);
};

export const unload = () => {
  if (document.body.hasAttribute(DUX_INITIALIZED_ATTRIBUTE)) {
    document.body.removeAttribute(DUX_INITIALIZED_ATTRIBUTE);
  }
};

import type {ListenerId} from '../utils/listeners';
import type {Track, Store} from '../types';
import {GtmEventType} from '../types';
import {getComponentTreeInfo} from '../utils/componentTree';
import {MonorailEventSchema} from '../../schema-types';
import {camelCaseToSnakeCaseAllProps} from '../../utils/camelCaseToSnakeCase';

export type Handler = (dom: HTMLElement, isIntersecting: boolean) => void;

let guid = 0;
let viewabilityObserver: IntersectionObserver;
const viewabilityHandlers: {
  [key: ListenerId]: {
    dom: HTMLElement;
    handler?: Handler;
  };
} = {};

const getViewabilityHandlerByDom = (
  dom: HTMLElement,
): {key?: string; handler?: Handler} => {
  const cbKey = Object.keys(viewabilityHandlers).find((key) => {
    const {dom: domRef} = viewabilityHandlers[key] || {};
    return domRef && domRef === dom;
  });
  return {
    key: cbKey,
    handler: cbKey ? viewabilityHandlers[cbKey].handler : undefined,
  };
};

export const removeViewabilityObserver = (
  dom: HTMLElement,
  id?: ListenerId,
) => {
  if (dom && viewabilityObserver) {
    viewabilityObserver.unobserve(dom);
  }

  // Clean up cached handlers on dom
  const {key} = getViewabilityHandlerByDom(dom);
  if (key) {
    delete viewabilityHandlers[key];
  }

  // Remove cached handlers by id if provided
  if (id) {
    delete viewabilityHandlers[id];
  }
};

export const addViewabilityObserver = (
  dom: HTMLElement,
  handler?: Handler,
): ListenerId | undefined => {
  if (dom) {
    guid += 1;
    const id = guid.toString();
    viewabilityHandlers[id] = {dom, handler};

    if (viewabilityObserver) {
      viewabilityObserver.observe(dom);
    }
    return id;
  }
};

export const handleViewabilityWrapper =
  (track: Track, store: Store): IntersectionObserverCallback =>
  (entries) => {
    entries.forEach((entry) => {
      // callback the optional handler in component
      const target: HTMLElement = entry?.target as HTMLElement;
      const {handler} = getViewabilityHandlerByDom(target);
      if (handler) {
        handler(target, entry.isIntersecting);
      }

      const {
        componentTree,
        extraMetadata,
        elementName,
        sectionName,
        sectionIndex,
      } = getComponentTreeInfo(target, false);

      // track and remove listeners (only triggers once per component per page)
      if (entry.isIntersecting) {
        track.dux({
          schemaId: MonorailEventSchema.ComponentViewability,
          payload: {
            pageViewToken: store.pageViewToken || '',
            componentTree,
            targetName: elementName,
            parentName: sectionName,
            parentIndex: sectionIndex,
            verticalPosition: Math.round(
              window.scrollY + target.getBoundingClientRect().top,
            ),
            extraMetadata,
          },
        });

        if (track.gtm) {
          track.gtm({
            event: GtmEventType.ComponentViewability,
            eventType: elementName,
            eventLocation: componentTree,
            extraMetadata: extraMetadata
              ? JSON.stringify(
                  camelCaseToSnakeCaseAllProps(
                    JSON.parse(extraMetadata || '{}'),
                  ),
                )
              : undefined,
          });
        }

        removeViewabilityObserver(target);
      }
    });
  };

export const initComponentViewabilityTracking = (
  track: Track,
  store: Store,
) => {
  if (
    (window && !('IntersectionObserver' in window)) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
  ) {
    return;
  }

  viewabilityObserver = new IntersectionObserver(
    handleViewabilityWrapper(track, store),
    {
      root: null,
      threshold: 0.2,
    },
  );

  document
    .querySelectorAll('[data-viewable-component]')
    .forEach((component) => addViewabilityObserver(component as HTMLElement));

  // The IntersectionObserver might be created async, it may not have existed when we tried to add observable targets
  // so observe them here if they already exist
  Object.keys(viewabilityHandlers).forEach((key) => {
    const {dom} = viewabilityHandlers[key];
    if (dom && viewabilityObserver) {
      viewabilityObserver.observe(dom);
    }
  });
};

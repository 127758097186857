export enum ApprovedCookie {
  MultiTrackToken = '_shopify_y',
  SessionToken = '_shopify_s',
  // The assignment cookie isn't the whole key as there is a hash prefix
  ExperimentAssignmentCookie = '_assignment',
  GoogleAnalyticsId = '_ga',
  Source = 'source',
  CookieNoticeAcknolwedged = 'eu_cookies_acknowledged',
  PrivacySignal = 'privacy_signal',
  LastShop = 'last_shop',
  IsShopifyMerchant = 'is_shopify_merchant',
  Affiliate = 'source',
}

export enum ComplianceZone {
  Gdpr = 'gdpr',
  Ccpa = 'ccpa',
  None = 'none',
}

export enum ConsentAllStatus {
  Accepted = '1',
  Declined = '-1',
}

export enum CookieNoticeBannerVariant {
  PassiveConsent = 'passive_consent',
  ActiveConsent = 'active_consent',
}

export enum RequestHeader {
  BasicAuth = 'x-basic-auth',
  CacheControl = 'cache-control',
  ContentType = 'content-type',
  Cookie = 'cookie',
  CfConnectingIp = 'cf-connecting-ip',
  CfIpcountry = 'cf-ipcountry',
  EdgeThreatScore = 'edge-threat-score',
  HttpXForwardedFor = 'http_x_forwarded_for',
  Origin = 'origin',
  OxygenBuyerIp = 'oxygen-buyer-ip',
  OxygenBuyerCountry = 'oxygen-buyer-country',
  OxygenBuyerRegionCode = 'oxygen-buyer-region-code',
  // Note: 'referer' mispelled on purposes
  Referrer = 'referer',
  UserAgent = 'user-agent',
  XBrochureDebugCountry = 'x-brochure-debug-country',
  XBrochureDomain = 'x-brochure-domain',
  XForwardedFor = 'x-forwarded-for',
  XForwardedHost = 'x-forwarded-host',
  XGeoCountryCode = 'x-geo-country-code',
  XGeoRegionCode = 'x-geo-region-code',
  XIpMetadata = 'x-ip-metadata',
  XRealIp = 'x-real-ip',

  // *** It's important this matches the RequestHeader expected in upstream origins (eg. Brochure2)
  XEdgeExp = 'x-edge-exp',
}

export enum RequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Head = 'HEAD',
  Options = 'OPTIONS',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export enum ResponseHeader {
  SetCookie = 'set-cookie',
  AccessControlAllowOrigin = 'access-control-allow-origin',
  AccessControlAllowCredentials = 'access-control-allow-credentials',
  AccessControlAllowMethods = 'access-control-allow-methods',
  AccessControlAllowHeaders = 'access-control-allow-headers',
}

export const enum SameSite {
  Lax = 'lax',
  Strict = 'strict',
  None = 'none',
}

import {onceListener} from '../utils/listeners';
import type {Track, Store} from '../types';
import {Events} from '../types';
import {MonorailEventSchema} from '../../schema-types';

export const initErrorTracking = (track: Track, store: Store) => {
  onceListener(Events.Error, (errorEvent: ErrorEvent) => {
    track.dux({
      schemaId: MonorailEventSchema.Error,
      payload: {
        pageViewToken: store.pageViewToken || '',
        message: errorEvent.message,
      },
    });
  });
};

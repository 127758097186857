import type {ReportCallback, Metric} from 'web-vitals';
import {onCLS, onFID, onLCP, onTTFB, onFCP} from 'web-vitals';

import {raceListeners} from '../utils/listeners';
import {getLastFpsDetected} from '../utils/webgl';
import type {Track, Store} from '../types';
import {Events} from '../types';
import {MonorailEventSchema} from '../../schema-types';

export const initWebVitalsTracking = (track: Track, store: Store) => {
  const webVitals: Metric[] = [];
  const queueWebVital: ReportCallback = (webVital) => {
    webVitals.push(webVital);
  };
  onCLS(queueWebVital);
  onFID(queueWebVital);
  onLCP(queueWebVital);
  onTTFB(queueWebVital);
  onFCP(queueWebVital);

  const sendWebVitals = () => {
    const payload: {[key: string]: number} = {};

    if (webVitals.length) {
      webVitals.forEach(({name, value, entries}: Metric) => {
        const dimension = name.toLocaleLowerCase();
        payload[dimension] = Math.round(value);

        if (name === 'CLS') {
          // measure the number of cummulative layout shifts?
          payload[`${dimension}Entries`] = entries.length;
        }
      });

      const fps = Math.round(getLastFpsDetected() || 0);

      track.dux(
        {
          schemaId: MonorailEventSchema.WebVitals,
          payload: {
            pageViewToken: store.pageViewToken || '',
            ...(fps ? {framesPerSecond: fps} : {}),
            ...(payload.ttfb ? {timeToFirstByte: payload.ttfb} : {}),
            ...(payload.fcp ? {firstContentfulPaint: payload.fcp} : {}),
            ...(payload.fid ? {firstInputDelay: payload.fid} : {}),
            ...(payload.lcp ? {largestContentfulPaint: payload.lcp} : {}),
            ...(payload.cls ? {cumulativeLayoutShift: payload.cls} : {}),
            ...(payload.clsEntries
              ? {cumulativeLayoutShiftEntries: payload.clsEntries}
              : {}),
          },
        },
        {flush: true},
      );
    }
  };

  raceListeners([
    [Events.BeforeUnload, sendWebVitals, document.body],
    [Events.VisibilityChange, sendWebVitals, document],
    [Events.PageHide, sendWebVitals, window],
  ]);
};

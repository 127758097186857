export type EventDescriptor = string;
export type ListenerId = string;
export type Context = any;
export type Callback = (...args: any[]) => void;

export interface Listener {
  callback: Callback;
  context: Context;
  listenerId: ListenerId;
  eventType: EventDescriptor;
}

export type ListenerArgs = [
  eventType: EventDescriptor,
  callback: Callback,
  context?: Context,
];

let guid = 0;
const listeners: {[id: ListenerId]: Listener} = {};

export function addListener(
  ...[eventType, callback, context = window]: ListenerArgs
): ListenerId {
  guid += 1;
  const id = guid.toString();
  context.addEventListener(eventType, callback);

  listeners[id] = {
    listenerId: id,
    eventType,
    context,
    callback,
  };

  return id;
}

export function removeListener(
  idOrTypeOrArrIds: ListenerId | EventDescriptor | ListenerId[],
  callback?: Callback,
) {
  if (typeof idOrTypeOrArrIds === 'object') {
    idOrTypeOrArrIds.forEach((id) => removeListener(id));
  } else {
    const id = idOrTypeOrArrIds.toString();
    if (listeners[id]) {
      const {context, eventType, callback: innerCallback} = listeners[id];
      context.removeEventListener(eventType, innerCallback);
      delete listeners[id];
    } else {
      Object.keys(listeners)
        .filter((key: string) => {
          const listener = listeners[key];
          return (
            listener.eventType === id &&
            (!callback || listener.callback === callback)
          );
        })
        .forEach((key) => removeListener(key));
    }
  }
}

export function removeAllListeners() {
  Object.keys(listeners).forEach((key) => removeListener(key));
}

/**
 * Executes callback and immediately removes listener
 * @param eventType
 * @param callback
 * @param context
 * @returns
 */
export function onceListener(
  ...[eventType, callback, context = window]: ListenerArgs
): ListenerId {
  const id = addListener(
    eventType,
    (...args) => {
      callback.apply(context, args);
      removeListener(id);
    },
    context,
  );
  return id;
}

/**
 * For browser interoperability sometimes we need to listen to multiple events, the first one that fires successfully
 * should remove all raced listeners and trigger the callback
 * @param listenersAr
 * @returns
 */
export function raceListeners(listenersAr: ListenerArgs[]): ListenerId[] {
  const ids: ListenerId[] = [];
  const removeRacedListeners =
    (callback: Callback, context: Context): Callback =>
    (...args) => {
      ids.forEach((id) => removeListener(id));
      callback.apply(context, args);
    };
  listenersAr.forEach(([eventType, callback, context = window]) => {
    ids.push(
      addListener(eventType, removeRacedListeners(callback, context), context),
    );
  });
  return ids;
}

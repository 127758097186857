/* eslint-disable @typescript-eslint/naming-convention */
export const DUX_INITIALIZED_ATTRIBUTE = 'data-dux-initialized';
export const CLICK_EVENT_SELECTORS =
  'a, button, [data-click-enabled], input, select';
export const GTM_DATA_SELECTORS =
  '[data-event-category], [data-ga-event], [data-ga-category]';

export const DOMAIN_LOCALE_MAPPINGS: {
  [key: string]: string | {[key: string]: string};
} = {
  'www.shopify.com': {
    default: 'en',
    nz: 'en-NZ',
    ph: 'en-PH',
  },
  'www.shopify.ca': 'en-CA',
  'www.shopify.co.uk': 'en-GB',
  'www.shopify.in': 'en-IN',
  'www.shopify.com.au': 'en-AU',
  'www.shopify.co.id': 'en-ID',
  'www.shopify.com.br': 'pt-BR',
  'www.shopify.co.nz': 'en-NZ',
  'www.shopify.co.za': 'en-ZA',
  'www.shopify.com.ng': 'en',
  'www.shopify.com.ph': 'en-PH',
  'www.shopify.com.sg': 'en-SG',
  'www.shopify.hk': 'zh-HK',
  'en.shopify.hk': 'en-HK',
  'www.shopify.ie': 'en-IE',
  'en.shopify.be': 'en-BE',
  'www.shopify.my': 'en-MY',
  'en.shopify.dk': 'en-DK',
  'en.shopify.no': 'en-NO',
  'es.shopify.com': 'es',
  'www.shopify.es': 'es-ES',
  'www.shopify.com.co': 'es-CO',
  'www.shopify.com.mx': 'es-MX',
  'www.shopify.fr': 'fr',
  'fr.shopify.ca': 'fr-CA',
  'fr.shopify.be': 'fr-BE',
  'www.shopify.nl': 'nl',
  'www.shopify.be': 'nl-BE',
  'www.shopify.jp': 'ja',
  'www.shopify.tw': 'zh-TW',
  'www.shopify.de': 'de',
  'de.shopify.be': 'de-BE',
  'it.shopify.com': 'it',
  'www.shopify.dk': 'da',
  'www.shopify.co.kr': 'ko',
  'www.shopify.se': 'sv',
  'zh.shopify.com': 'zh-CN',
  'www.shopify.net.vn': 'vi',
};

export const HOME_HANDLE = 'home';

import type {IRequest} from 'itty-router';

import type {MonorailEvent} from './schema-types';

export * from './enums';
export type * from './schema-types';
export interface ConfigMetadata {
  site?: Site;
  page: DataLayer;
  pageId?: string;
  handle?: string;
  regions: Region[];
  title?: string;
  rootDomain?: string;
  extraData?: {
    currencyCode?: string;
  };
}

export type Mode = 'development' | 'production' | 'staging' | 'storybook';
export type DuxMonorailEvent = MonorailEvent;
export interface DataLayer {
  affiliate?: string;
  blogCategory?: string;
  blogId?: string;
  continentCode?: string;
  countryCurrencyCode?: string;
  environment?: string;
  experimentVariationId?: string;
  httpCode?: string;
  language?: string;
  pageCategory?: string;
  pageGroup?: string;
  pageSubtopic?: string;
  pageTopic?: string;
}

export interface Region {
  domain: string;
  locale?: string;
  pathPrefix?: string;
  label: string;
  hreflang: string;
  lang: string;
  selector: boolean;
  // set by loader data
  href?: string;
  base?: boolean;
  isActive?: boolean;
}

/**
 * Site configuration
 * @property {string} countryCode - This is NOT the User countryCode. ISO 3166-1 alpha-2 country code of the domain/site.
 */
export interface Site {
  countryCode?: string;
  countryCurrencyCode?: string;
  currencyCode?: string;
  domain?: string;
  features?: string[];
  googleVerificationCodes?: string[];
  hardwareStoreUrl?: string;
  hreflang?: string;
  languageCode?: string;
  locale?: string;
  pathPrefix?: string;
  baseEnDomain?: boolean;
}

export interface DuxPageEvent {
  url: string;
  userLanguage: string;
  httpStatusCode: string;
  experimentVariationId: string;
  referrer?: string;
  metadata: ConfigMetadata;
}

export type DuxEvent = DuxPageEvent | DuxMonorailEvent;

export interface EventMessage {
  multiTrackToken: string;
  sessionToken: string;
  pageViewToken: string;
  siteEnvironment: Mode;
  events: DuxEvent[];
  url: string;
}

export const isMonorailEvent = (event: DuxEvent): event is MonorailEvent => {
  return (event as MonorailEvent).schemaId !== undefined;
};

export const isDuxEvent = (event: DuxEvent): event is DuxPageEvent => {
  return (event as DuxPageEvent).url !== undefined;
};

// From: @cloudflare/workers-types/2022-11-30
interface IncomingRequestCfProperties {
  botManagement?: {
    ja3Hash?: string;
    score?: number;
    detectionIds?: number[];
    staticResource?: boolean;
    verifiedBot?: boolean;
  };
  // https://developers.cloudflare.com/ruleset-engine/rules-language/fields/#dynamic-fields
  // Represents a Cloudflare threat score from 0–100, where 0 indicates low risk.
  // Values above 10 may represent spammers or bots, and values above 40 identify bad actors on the Internet.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  threat_score?: number;

  // https://developers.cloudflare.com/workers/runtime-apis/request/#incomingrequestcfproperties:~:text=%22Texas%22.-,regionCode,-string%20%7C%20null
  regionCode?: string;
}

export type AnyRequest = Request | IRequest;
declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
    utag: any;
    cmp: any;
  }

  // Extend Request interface to include Cloudflare properties, if available
  interface Request {
    cf?: IncomingRequestCfProperties;
  }
}

import debounce from '../utils/debounce';
import type {ListenerId} from '../utils/listeners';
import {addListener, removeListener} from '../utils/listeners';
import type {Track, Store} from '../types';
import {Events} from '../types';
import {MonorailEventSchema} from '../../schema-types';

export const initScrollTracking = (track: Track, store: Store) => {
  const listenerAr: ListenerId[] = [];
  let maxScrollY = 0;
  let maxQuadrant = 0;

  const measureScrollDistance = () => {
    const totalHeight =
      document?.documentElement?.scrollHeight || document?.body?.scrollHeight;
    const scrollHeight = totalHeight - document?.documentElement?.clientHeight;
    const scrollTop =
      document?.documentElement?.scrollTop || document?.body?.scrollTop;
    const percScrolled = scrollTop
      ? Math.round((scrollTop / scrollHeight) * 100)
      : 0;

    if (percScrolled > maxScrollY) {
      maxScrollY = percScrolled;
      if (maxScrollY < 25) {
        maxQuadrant = 0;
      } else if (maxScrollY < 50) {
        maxQuadrant = 25;
      } else if (maxScrollY < 75) {
        maxQuadrant = 50;
      } else if (maxScrollY < 95) {
        maxQuadrant = 75;
      } else {
        maxQuadrant = 100;
      }
    }

    return maxQuadrant;
  };

  const trackMaxScrollDistance = () => {
    const quadrant = measureScrollDistance();
    trackQuadrant(quadrant);
  };

  const trackQuadrant = (quadrant: number) => {
    track.dux(
      {
        schemaId: MonorailEventSchema.Scroll,
        payload: {
          pageViewToken: store.pageViewToken || '',
          quadrant,
        },
      },
      {flush: true},
    );

    if (track.gtm) {
      track.gtm({
        event: Events.Scroll,
        scrollDepth: quadrant,
      });
    }

    // cleanup, we only want 1 event per page
    listenerAr.forEach((id) => removeListener(id));
  };

  const calcuateScroll = debounce(() => {
    const quadrant = measureScrollDistance();
    if (quadrant === 100) trackQuadrant(quadrant);
  });

  // measure scroll distance, log it immediately if we hit ~100% (95%+)
  // trackQuardrant will remove all listeners
  listenerAr.push(
    ...[
      addListener(Events.Scroll, calcuateScroll, document),
      addListener(Events.BeforeUnload, trackMaxScrollDistance, document.body),
      // provides an estimate for page exit because beforeUnload is not reliable
      // in iOS users can background Safari and then close Safari resulting in no `beforeUnload` event
      addListener(Events.VisibilityChange, trackMaxScrollDistance, document),
      addListener(Events.PageHide, trackMaxScrollDistance, window),
    ],
  );

  calcuateScroll();
};
